
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { AttrDetail } from '@/types/product'
@Component
@KeepAlive
export default class AttrList extends Vue {
  private loading = false
  private info: Array<AttrDetail> = []

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.product.selectProductAttributeByPage).then((res) => {
      this.info = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productAttrAdd'
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productAttrEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该属性吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteProductAttribute, {
        attributeId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }

  // 排序
  onSort (index: number, isUp: boolean) {
    if (isUp) {
      [this.info[index - 1], this.info[index]] = [this.info[index], this.info[index - 1]]
    } else {
      [this.info[index], this.info[index + 1]] = [this.info[index + 1], this.info[index]]
    }
  }

  onSortUpdate (index: number, isUp: boolean) {
    this.onSort(index, isUp)
    const ids = this.info.map(item => {
      return {
        attributeId: item.attributeId
      }
    })
    this.$axios.post(this.$apis.product.updateOrderNum, ids).then(() => {
      // 重新复制，否则vue不刷新页面
      this.info = [...this.info]
    }).catch(() => {
      // 如果失败，还原排序
      this.onSort(isUp ? index - 1 : index + 1, !isUp)
    })
  }
}
